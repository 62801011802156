import { Injectable } from '@angular/core';
import { AnalyticsEvent } from '@common/analytics/events/analytics.event';
import { MParticleService } from './m-particle.service';

@Injectable({
    providedIn: 'root',
})
export class NoOpMParticleService extends MParticleService {
    public initializeMParticle(): void {}
    public login(registration: any): void {}
    public logout(): void {}
    public push(event: AnalyticsEvent): void {}
}
