<div class="card shadow border-0 mb-3 sticky-top-md">
    <div class="card-body">
        <div class="row">
            <div class="col mx-10 d-flex flex-wrap">
                <div class="mr-auto nls_protected">
                    {{ 'registration-bar-number' | cmsText | async }}:
                    {{ registration.oldRequestId || registration.number }}
                </div>
                <div class="nls_protected">
                    <i class="fa fa-user me-2"></i
                    >{{ registration.contact.initials }}
                    {{ registration.contact.insertion }}
                    {{ registration.contact.lastName }}
                    <i class="fa fa-map-marker mx-2"></i
                    >{{ registration.contact.street }}
                    {{ registration.contact.houseNr }},
                    {{ registration.contact.city }}
                </div>
            </div>
        </div>
    </div>
</div>
