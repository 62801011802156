import { APP_BASE_HREF, Location } from '@angular/common';
import { AfterViewInit, Component, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AnalyticsService } from '@common/analytics/services/analytics.service';
import { AnalyticsClickService } from '@common/analytics/services/click.service';
import { MParticleService } from '@common/mparticle/services/m-particle.service';
import { AuthService } from '@common/services/auth.service';
import { MonitoringService } from '@common/services/monitoring.service';
import { WINDOW } from '@common/services/window.service';
import { filter, map } from 'rxjs/operators';
import { AppBase } from '../../_common/app/app.component.base';
import { CommunityService } from '../../_common/services/community.service';
import { ContentService } from '../../_common/services/content.service';
import { GoogleAnalyticsService } from '../../_common/services/google-analytics.service';
import { KenalyticsService } from '../../_common/services/kenalytics.service';
import { SeoService } from '../../_common/services/seo.service';
import { VehUtmQueryParameter } from '@country/nl/enums/vehUtmQueryParameter';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent extends AppBase implements AfterViewInit {
    doneLoadingIdentity$ = this.authService.identity$.pipe(map(() => true));

    constructor(
        protected authService: AuthService,
        private monitoringService: MonitoringService,
        router: Router,
        location: Location,
        analytics: GoogleAnalyticsService,
        titleService: Title,
        communityService: CommunityService,
        route: ActivatedRoute,
        contentService: ContentService,
        seoService: SeoService,
        kenalyticsService: KenalyticsService,
        analyticsService: AnalyticsService,
        @Inject(WINDOW) window: Window,
        @Inject(APP_BASE_HREF) baseHref: string,
        clickService: AnalyticsClickService,
        mParticleService: MParticleService,
    ) {
        super(
            router,
            location,
            analytics,
            titleService,
            communityService,
            route,
            contentService,
            seoService,
            kenalyticsService,
            analyticsService,
            window,
            baseHref,
            clickService,
            mParticleService,
        );

        this.monitoringService.logEvent('spnl-app-constructed', {
            url: window.location.href,
            // A bit rough measure, but needed to quickly debug SPNL-776:
            sessionStorageRegistrationStartBits: sessionStorage
                ?.getItem('registration-spnl-veh')
                ?.substr(0, 75),
        });

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((_) => this.monitoringService.logPageView());

        this.setTitle('ichoosr App');

        }

        ngAfterViewInit(): void {
            this.route.queryParamMap
            .subscribe((params) => {
                const utmSource = params.get(VehUtmQueryParameter.Source);
                if (utmSource) {
                    const campaign = params.get(VehUtmQueryParameter.Campaign);
                    const content = params.get(VehUtmQueryParameter.Content);
                    const medium = params.get(VehUtmQueryParameter.Medium);
                    const term = params.get(VehUtmQueryParameter.Term);
                    this.kenalyticsService.saveToSessionStorage(
                        utmSource,
                        campaign,
                        content,
                        medium,
                        term,
                    );
                }
            });
    }
}
