<form
    class="mt-3"
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
    novalidate
    appfocusFirstInvalidField
>
    <h3 class="my-3">
        <span
            [innerHTML]="introductionCmsKey + '-header' | cmsText | async"
        ></span>
    </h3>
    <ng-container *ngIf="shouldShowStepCount">
        <span
            [innerHTML]="
                'roof-step-description'
                    | cmsText : [{ currentStep: 7, stepCount: 7 }]
                    | async
            "
        ></span
        >:
        <strong class="mb-2">{{ 'personal-details' | cmsText | async }}</strong>
    </ng-container>
    <p
        class="mt-2"
        [innerHTML]="introductionCmsKey + '-short-text' | cmsText | async"
    ></p>

    <hr class="my-3" />
    <fieldset>
        <div class="mb-3" formGroupName="contact">
            <label for="salutation">
                <span [innerHTML]="'salutation-title' | cmsText | async"></span>
            </label>
            <div class="form-check">
                <input
                    type="radio"
                    name="salutation"
                    [ngClass]="{
                        'is-invalid': showErrorsForSalutation
                    }"
                    class="form-check-input"
                    id="Meneer"
                    formControlName="salutation"
                    value="Mr"
                />
                <label
                    class="custom-control-label form-check-label"
                    for="Meneer"
                >
                    {{ 'salutation-mr-label' | cmsText | async }}
                </label>
            </div>
            <div class="form-check">
                <input
                    type="radio"
                    name="salutation"
                    [ngClass]="{
                        'is-invalid': showErrorsForSalutation
                    }"
                    class="form-check-input"
                    id="Mevrouw"
                    formControlName="salutation"
                    value="Mrs"
                />
                <label
                    class="custom-control-label form-check-label"
                    for="Mevrouw"
                >
                    {{ 'salutation-mrs-label' | cmsText | async }}
                </label>
            </div>
            <app-register-input-errors
                [shouldShowErrors]="showErrorsForSalutation"
                [field]="salutation"
                label="salutation"
            ></app-register-input-errors>
        </div>

        <div class="row" formGroupName="contact">
            <div class="col-md-3 mb-3">
                <label for="initials">{{
                    'initials-label' | cmsText | async
                }}</label>
                <input
                    (blur)="formatInitials()"
                    class="form-control"
                    [ngClass]="{
                        'is-invalid': showErrorsForInitials
                    }"
                    id="initials"
                    formControlName="initials"
                />
                <app-register-input-errors
                    [shouldShowErrors]="showErrorsForInitials"
                    [field]="initials"
                    label="initials"
                ></app-register-input-errors>
            </div>
            <div class="col-md-3 mb-3">
                <label for="insertion">
                    {{ 'insertion-label' | cmsText | async }}
                </label>
                <input
                    class="form-control"
                    [ngClass]="{
                        'is-invalid': showErrorsForInsertion
                    }"
                    id="insertion"
                    formControlName="insertion"
                />
                <app-register-input-errors
                    [shouldShowErrors]="showErrorsForInsertion"
                    [field]="insertion"
                    label="insertion"
                ></app-register-input-errors>
            </div>
            <div class="col-md-6 mb-3">
                <label for="lastName">
                    {{ 'last-name-label' | cmsText | async }}
                </label>
                <input
                    class="form-control"
                    [ngClass]="{
                        'is-invalid': showErrorsForLastName
                    }"
                    id="lastName"
                    formControlName="lastName"
                />
                <app-register-input-errors
                    [shouldShowErrors]="showErrorsForLastName"
                    [field]="lastName"
                    label="last-name"
                ></app-register-input-errors>
            </div>
        </div>

        <div class="row" formGroupName="contact">
            <div class="col-md-3 mb-3">
                <label for="zip">
                    {{ 'zip-label' | cmsText | async }}
                </label>
                <input
                    id="zip"
                    formControlName="zip"
                    (blur)="normalizeZip()"
                    class="form-control"
                    [ngClass]="{
                        'is-invalid': showErrorsForZip
                    }"
                />
                <app-register-input-errors
                    [shouldShowErrors]="showErrorsForZip"
                    [field]="zip"
                    label="zip"
                >
                </app-register-input-errors>
            </div>
            <div class="col-md-3 mb-3">
                <label for="houseNr">
                    {{ 'housenr-label' | cmsText | async }}
                </label>
                <input
                    id="houseNr"
                    formControlName="houseNr"
                    class="form-control"
                    [ngClass]="{
                        'is-invalid': showErrorsForHouseNr
                    }"
                />
                <app-register-input-errors
                    [shouldShowErrors]="showErrorsForHouseNr"
                    [field]="houseNr"
                    label="housenr"
                ></app-register-input-errors>
            </div>
            <div class="col-md-3 mb-3 typeahead">
                <label for="houseNrSuffix">
                    {{ 'housenr-suffix-label' | cmsText | async }}
                </label>
                <input
                    type="text"
                    class="form-control"
                    [ngClass]="{
                        'is-invalid': showErrorsForHouseNrSuffix
                    }"
                    id="houseNrSuffix"
                    formControlName="houseNrSuffix"
                    [ngbTypeahead]="houseNumberSuffixValueOptions"
                    (focus)="
                        houseNumberSuffixFocus$.next($any($event).target.value)
                    "
                    (click)="
                        houseNumberSuffixClick$.next($any($event).target.value)
                    "
                    #instance="ngbTypeahead"
                />
                <app-register-input-errors
                    [shouldShowErrors]="showErrorsForHouseNrSuffix"
                    [field]="houseNrSuffix"
                    label="housenr-suffix"
                ></app-register-input-errors>
            </div>
            <app-register-warning
                warningKey="zip-error-not-supported"
                *ngIf="!matchesPostcodeRange"
            ></app-register-warning>
            <app-register-warning
                warningKey="zip-not-found"
                *ngIf="!readonlyAddress"
            >
            </app-register-warning>
        </div>

        <div class="row" formGroupName="contact">
            <div class="col-md-6 mb-3">
                <label for="street">
                    {{ 'street-label' | cmsText | async }}
                </label>
                <div *ngIf="!hideAddressSpinners">
                    <app-loading-spinner size="large"></app-loading-spinner>
                </div>
                <div *ngIf="hideAddressSpinners">
                    <input
                        id="street"
                        formControlName="street"
                        class="form-control"
                        [ngClass]="{
                            'is-invalid': showErrorsForStreet
                        }"
                        [readOnly]="readonlyAddress"
                    />
                    <app-register-input-errors
                        [shouldShowErrors]="showErrorsForStreet"
                        [field]="street"
                        label="street"
                    ></app-register-input-errors>
                </div>
            </div>

            <div class="col-md-6 mb-3">
                <label for="city">
                    {{ 'city-label' | cmsText | async }}
                </label>
                <div *ngIf="!hideAddressSpinners">
                    <app-loading-spinner size="large"></app-loading-spinner>
                </div>
                <div *ngIf="hideAddressSpinners">
                    <input
                        id="city"
                        formControlName="city"
                        class="form-control"
                        [ngClass]="{
                            'is-invalid': showErrorsForCity
                        }"
                        [readonly]="readonlyAddress"
                    />
                    <app-register-input-errors
                        [shouldShowErrors]="showErrorsForCity"
                        [field]="city"
                        label="city"
                    ></app-register-input-errors>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 mb-3">
                <label for="email">
                    {{ 'email-label' | cmsText | async }}
                </label>
                <input
                    id="email"
                    formControlName="email"
                    inputmode="email"
                    class="form-control"
                    [ngClass]="{
                        'is-invalid': showErrorsForEmail
                    }"
                />
                <app-register-input-errors
                    [shouldShowErrors]="showErrorsForEmail"
                    [field]="email"
                    label="email"
                >
                </app-register-input-errors>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 mb-3" formGroupName="contact">
                <label for="cellPhone">
                    {{ 'cell-phone-label' | cmsText | async }}
                </label>
                <input
                    id="cellphone"
                    formControlName="cellPhone"
                    inputmode="tel"
                    (blur)="formatPhone(cellPhone)"
                    class="form-control"
                    [ngClass]="{
                        'is-invalid': phoneInvalid(cellPhone)
                    }"
                />
                <app-register-input-errors
                    [shouldShowErrors]="
                        showErrorsForCellPhone &&
                        !contactForm.hasError('phoneRequired') &&
                        !contactForm.hasError('cellPhoneInvalid')
                    "
                    [field]="cellPhone"
                    label="cellphone"
                ></app-register-input-errors>

                <app-register-text-error
                    *ngIf="phoneMissing"
                    errorKey="phone-error-required"
                >
                </app-register-text-error>
                <app-register-text-error
                    *ngIf="cellPhoneInvalid"
                    errorKey="cell-phone-number-invalid"
                ></app-register-text-error>
            </div>
            <div class="col-md-6 mb-3" formGroupName="contact">
                <label for="phone">
                    {{ 'phone-label' | cmsText | async }}
                </label>
                <input
                    id="phone"
                    formControlName="phone"
                    inputmode="tel"
                    (blur)="formatPhone(phone)"
                    class="form-control"
                    [ngClass]="{
                        'is-invalid': phoneInvalid(phone)
                    }"
                />
                <app-register-input-errors
                    [shouldShowErrors]="
                        showErrorsForPhone &&
                        !contactForm.hasError('phoneRequired')
                    "
                    [field]="phone"
                    label="phone"
                ></app-register-input-errors>
            </div>
        </div>
    </fieldset>

    <hr class="my-3" />
    <fieldset>
        <div class="radio" formGroupName="product">
            <label for="communityMember">
                {{ 'community-member-label' | cmsText | async }}
            </label>
            <div class="form-check">
                <input
                    type="radio"
                    name="communityMember"
                    class="form-check-input"
                    [ngClass]="{
                        'is-invalid': showErrorsForCommunityMember
                    }"
                    id="CommunityMemberYes"
                    formControlName="communityMember"
                    [value]="CommunityMember.Yes"
                />
                <label class="custom-control-label" for="CommunityMemberYes">
                    {{ 'yes' | cmsText | async }}
                </label>
            </div>
            <div class="form-check">
                <input
                    type="radio"
                    name="communityMember"
                    class="form-check-input"
                    [ngClass]="{
                        'is-invalid': showErrorsForCommunityMember
                    }"
                    id="CommunityMemberNo"
                    formControlName="communityMember"
                    [value]="CommunityMember.No"
                />
                <label class="custom-control-label" for="CommunityMemberNo">
                    {{ 'no' | cmsText | async }}
                </label>
            </div>
            <div class="form-check">
                <input
                    type="radio"
                    name="communityMember"
                    class="form-check-input"
                    [ngClass]="{
                        'is-invalid': showErrorsForCommunityMember
                    }"
                    id="CommunityMemberUnknown"
                    formControlName="communityMember"
                    [value]="CommunityMember.Unknown"
                />
                <label
                    class="custom-control-label"
                    for="CommunityMemberUnknown"
                >
                    {{ 'community-member-unknown' | cmsText | async }}
                </label>
            </div>
            <app-register-input-errors
                [shouldShowErrors]="showErrorsForCommunityMember"
                [field]="communityMember"
                label="community-member"
            >
            </app-register-input-errors>
        </div>
    </fieldset>
    <hr class="my-3" />
    <fieldset>
        <div class="mb-0 radio" formGroupName="contact">
            <div class="form-check">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="agreesToConditions"
                    formControlName="agreesToConditions"
                    required
                />
                <label class="custom-control-label" for="agreesToConditions">
                    <span
                        [innerHTML]="
                            'agrees-to-conditions-label' | cmsText | async
                        "
                    ></span>
                </label>
                <br />
                <ng-container *ngIf="showErrorsForAgreesToConditions">
                    <app-register-text-error
                        *ngIf="agreesToConditions.value !== true"
                        errorKey="agrees-to-conditions-error-required"
                    >
                    </app-register-text-error>
                </ng-container>
            </div>
        </div>
    </fieldset>

    <div *ngIf="!registration.isInInterestedIndividualFlow">
        <fieldset>
            <div class="mb-0 radio" formGroupName="contact">
                <div class="form-check">
                    <input
                        type="checkbox"
                        class="form-check-input"
                        id="agreesToFurtherContact"
                        formControlName="agreesToFurtherContact"
                    />
                    <label
                        class="custom-control-label"
                        for="agreesToFurtherContact"
                    >
                        <span
                            [innerHTML]="
                                'agrees-to-further-contact-label'
                                    | cmsText
                                    | async
                            "
                        ></span>
                    </label>
                </div>
            </div>
        </fieldset>
    </div>
    <!-- RESPONSIVE FORM BUTTONS -->
    <hr class="mb-3 mt-0" />
    <div class="row">
        <div class="col-md-12">
            <div class="button-wrapper btn-container-start">
                <button
                    *ngIf="allowPrevious"
                    type="button"
                    class="btn btn-outline-primary px-5 order-sm-first order-last"
                    (click)="goBack()"
                >
                    <i class="fa fa-chevron-left me-2"></i
                    >{{ 'go-back-button' | cmsText | async }}
                </button>
                <button
                    id="nextButton"
                    type="submit"
                    [disabled]="submitting && form.valid"
                    class="btn btn-primary px-5 order-sm-last order-first"
                    *ngIf="!shouldBeExcluded"
                >
                    <i
                        *ngIf="submitting && form.valid"
                        class="fa fa-spinner fa-spin me-1"
                    ></i>
                    {{ 'button-next-label' | cmsText | async
                    }}<i class="fa fa-chevron-right ms-2"></i>
                </button>
                <button
                    id="excludeButton"
                    type="submit"
                    [disabled]="submitting && form.valid"
                    class="btn btn-primary px-5 order-sm-last order-first"
                    *ngIf="shouldBeExcluded"
                    (click)="exclude()"
                >
                    <i
                        *ngIf="submitting && form.valid"
                        class="fa fa-spinner fa-spin me-1"
                    ></i>
                    {{ 'button-exclude-label' | cmsText | async }}
                    <i class="fa fa-chevron-right ms-1"></i>
                </button>
                <div
                    [innerHtml]="'button-exclude-description' | cmsText | async"
                    *ngIf="shouldBeExcluded"
                    class="my-2 order-last"
                ></div>
            </div>
        </div>
    </div>
</form>
