import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModule } from 'ng2-file-upload';

import { AppCommonModule } from '@common/app-common.module';
import {
    ANALYTICS_API_URL,
    ANALYTICS_LOG_TO_CONSOLE,
    ANALYTICS_SEND_TO_TAGMANAGER,
    API_URL, APPLICATION_INSIGHTS_CONNECTION_STRING,
    BUSINESS,
    CANONICAL_COMMUNITY,
    CMS_ALLOW_LINKS,
    CMS_LOCALE,
    CMS_PRODUCT,
    CMS_REGION,
    CMS_URL,
    COMMON_API_URL,
    configureOAuth,
    COOKIE_POLICY_URL,
    DEFAULT_COMMUNITY,
    fetchCookiePolicyUrlFromEnv,
    getAuthConfigFromEnv,
    MPARTICLE_DEVELOPMENT_MODE,
    MPARTICLE_KEY_PROVIDER_URL,
    PAYMENTS_API_URL,
} from '@common/app/app.config';
import { NgSelectModule } from '@ng-select/ng-select';
import {
    AuthConfig,
    OAuthModule,
    OAuthModuleConfig,
    OAuthStorage,
    ValidationHandler,
} from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { NgxMaskModule } from 'ngx-mask';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';

import localeNL from '@angular/common/locales/nl';
import { PendingChangesGuard } from '@common/infrastructure/pending-changes.guard';
import { CmsTextPipe } from '@common/pipes/cms-text.pipe';
import { AuthService } from '@common/services/auth.service';
import { ChatService } from '@common/services/chat.service';
import { ContactService } from '@common/services/contact.service';
import { ContentFallbackServiceBase } from '@common/services/content-fallback.service.base';
import { ContentService } from '@common/services/content.service';
import { InMemoryAuthStorage } from '@common/services/in-memory-auth-storage';
import { InMemoryQueryService } from '@common/services/inmemory.query.service';
import { KenalyticsService } from '@common/services/kenalytics.service';
import { PaymentService } from '@common/services/payment.service';
import { SupplierService } from '@common/services/supplier.service';
import { VwoService } from '@common/services/vwo.service';
import { VwoStoreService } from '@common/services/vwo-store.service';
import { WINDOW_PROVIDERS } from '@common/services/window.service';
import { AddressService } from '@lang/nl/services/address.service';
import { AuctionService } from '@sp/services/auction.service';
import { ProductComponent } from '@spnl/components/register/product/product.component';
import { AppRoutingModule } from './app-routing.module';
import { CallToActionModule } from './components/call-to-action/call-to-action.module';
import { CancelErrorComponent } from './components/cancel/cancel-error.component';
import { CancelFormComponent } from './components/cancel/cancel-form.component';
import { CancelNextStepsComponent } from './components/cancel/cancel-next-steps.component';
import { CancelOverviewTitleComponent } from './components/cancel/cancel-overview-title.component';
import { CancelSidebarComponent } from './components/cancel/cancel-sidebar.component';
import { CancelSuccessComponent } from './components/cancel/cancel-success.component';
import { CancelComponent } from './components/cancel/cancel.component';
import { RegistrationBarComponent } from './components/cancel/registration-bar.component';
import { ContactComponent } from './components/contact/contact.component';
import { ErrorStepComponent } from './components/error-step/error-step.component';
import { FaqComponent } from './components/faq/faq.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeroComponent } from './components/hero/hero.component';
import { MenuComponent } from './components/menu/menu.component';
import { PrivacyStatementComponent } from './components/privacy-statement/privacy-statement.component';
import { RegisterCancelLinkComponent } from './components/register/cancel-link/cancel-link.component';
import { CancelledComponent } from './components/register/cancelled/cancelled.component';
import { ClosedComponent } from './components/register/closed/closed.component';
import { DecisionMadeComponent } from './components/register/decision-made/decision-made.component';
import { DepositFailedComponent } from './components/register/decision-made/offer-accepted/deposit-failed/deposit-failed.component';
import { OfferAcceptedComponent } from './components/register/decision-made/offer-accepted/offer-accepted.component';
import { OfferDeclinedComponent } from './components/register/decision-made/offer-declined/offer-declined.component';
import { PaymentComponent } from './components/register/decision-made/payment/payment.component';
import { DepositComponent } from './components/register/deposit/deposit.component';
import { RegisterErrorComponent } from './components/register/error/error.component';
import { RegisterInputErrorsComponent } from './components/register/error/input-errors.component';
import { RegisterTextErrorComponent } from './components/register/error/text-error.component';
import { ExcludeReasonComponent } from './components/register/exclude-reason/exclude-reason.component';
import { FaqCategoryComponent } from './components/register/faq/faq-category.component';
import { RegisterFaqComponent } from './components/register/faq/register-faq.component';
import { OverviewChangeDetailsComponent } from './components/register/overview/change-details/overview-change-details.component';
import { DepositPaidComponent } from './components/register/overview/deposit-paid/deposit-paid.component';
import { OrderDetailsComponent } from './components/register/overview/deposit-paid/order-details/order-details.component';
import { DepositPendingComponent } from './components/register/overview/deposit-pending/deposit-pending.component';
import { InterestedIndividualComponent } from './components/register/overview/interested-individual/interested-individual.component';
import { OverviewNextStepsComponent } from './components/register/overview/next-steps/overview-next-steps.component';
import { OverviewComponent } from './components/register/overview/overview.component';
import { PaymentSuccessComponent } from './components/register/overview/payment-success/payment-success.component';
import { ProposalAttachmentsComponent } from './components/register/overview/proposal/proposal-attachments.component';
import { PersonComponent } from './components/register/person/person.component';
import { RegisterWarningButtonComponent } from './components/register/person/warning-button/warning-button.component';
import { ExcludedComponent } from './components/register/product/excluded/excluded.component';
import { ProposalAdvantageComponent } from './components/register/proposal/proposal-advantage/proposal-advantage.component';
import { ProposalContactDataComponent } from './components/register/proposal/proposal-contact-data/proposal-contact-data.component';
import { ProposalCountdownComponent } from './components/register/proposal/proposal-countdown/proposal-countdown.component';
import { ProposalDeclineModalComponent } from './components/register/proposal/proposal-decline-modal/proposal-decline-modal.component';
import { ProposalInstallationComponent } from './components/register/proposal/proposal-installation/proposal-installation.component';
import { ProposalNotesToSupplierComponent } from './components/register/proposal/proposal-notes-to-supplier/proposal-notes-to-supplier.component';
import { ProposalOfferComponent } from './components/register/proposal/proposal-offer/proposal-offer.component';
import { ProposalRoofDataComponent } from './components/register/proposal/proposal-roof-data/proposal-roof-data.component';
import { ProposalSupplierConditionsComponent } from './components/register/proposal/proposal-supplier-conditions/proposal-supplier-conditions.component';
import { ProposalComponent } from './components/register/proposal/proposal.component';
import { SupplierLogoComponent } from './components/register/proposal/supplier-logo/supplier-logo.component';
import { RegisterRedirectComponent } from './components/register/register-redirect.component';
import { RegisterStatusMessageComponent } from './components/register/register-status-message/register-status-message.component';
import { RegisterStepperComponent } from './components/register/register-stepper.component';
import { RegisterComponent } from './components/register/register.component';
import { RoofDetailsComponent } from './components/register/roof-details/roof-details.component';
import { RoofEnergyComponent } from './components/register/roof-details/roof-energy/roof-energy.component';
import { RoofExcludedWarningComponent } from './components/register/roof-details/roof-excluded-warning/roof-excluded-warning.component';
import { RoofFormButtonsComponent } from './components/register/roof-details/roof-form-buttons/roof-form-buttons.component';
import { RoofObjectsComponent } from './components/register/roof-details/roof-objects/roof-objects.component';
import { RoofSizeComponent } from './components/register/roof-details/roof-size/roof-size.component';
import { RegisterExcludeButtonComponent } from './components/register/roof-details/roof-type/exclude-button/exclude-button.component';
import { RoofTypeComponent } from './components/register/roof-details/roof-type/roof-type.component';
import { RoofYieldComponent } from './components/register/roof-details/roof-yield/roof-yield.component';
import { StepTitleComponent } from './components/register/roof-details/step-title/step-title.component';
import { UserCardComponent } from './components/register/user/user-card.component';
import { RegisterWarningComponent } from './components/register/warning/warning.component';
import { EmailConfirmedComponent } from './components/register/welcome/email-confirmed/email-confirmed.component';
import { WelcomeComponent } from './components/register/welcome/welcome.component';
import { SeminarCardComponent } from './components/seminar/seminar-card.component';
import { SeminarComponent } from './components/seminar/seminar.component';
import { LoadingSpinnerComponent } from './components/shared/loader/loading-spinner.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { UserChangedComponent } from './components/user-changed/user-changed.component';
import { FormatAddressPipe } from './pipes/format-address.pipe';
import { FullNamePipe } from './pipes/full-name-pipe';
import { ContentFallbackService } from './services/content-fallback.service';
import { ContentTranslatorService } from './services/content-translator.service';
import { DigitalEventService } from './services/digital-event-queue.service';
import { ErrorHandlerService } from './services/error-handler.service';
import { FaqAnalyticsService } from './services/faq-analytics.service';
import { InstallationService } from './services/installation.service';
import { ReferrerService } from './services/referrer.service';
import { RegisterAnalyticsService } from './services/register-analytics.service';
import { RegisterFlowCalculator } from './services/register-flow-calculator.service';
import { RegisterLocationService } from './services/register-location.service';
import { RegisterSaveService } from './services/register-save.service';
import { RegisterStateService } from './services/register-state.service';
import { RegisterStepService } from './services/register-step.service';
import { RegisterStoreService } from './services/register-store.service';
import { RegistrationButtonContentService } from './services/registration-button-content.service';
import { RegistrationStoreService } from './services/registration-store.service';
import { RegistrationService } from './services/registration.service';
import { RegistrationApiService } from './services/registration-api.service';
import { RoofDetailsLocationService } from './services/roof-details-location.service';
import { SolarApiService } from './services/solar-api.service';
import { AnalyticsEnricherProvider } from '@common/analytics/services/analytics-enricher.service';
import { CommunityService } from '@common/services/community.service';
import { MParticleService } from '@common/mparticle/services/m-particle.service';
import { NoOpMParticleService } from '@common/mparticle/services/no-op-m-particle.service';
import { AppCommonCoreModule } from '@common/app-common-core.module';
import { SpnlTooltipComponent } from './components/tooltip/tooltip.component';
import { PreferencesService } from '../../_country/nl/services/preferences.service';
import { ContactPreferencesComponent } from './components/contact-preferences/contact-preferences.component';
import { CancelRegistrationConfirmModalComponent } from './components/contact-preferences/cancel-registration-confirm-modal.component';
import { RedirectToPreferenceCenterComponent } from './components/redirect-to-preference-center/redirect-to-preference-center-component';
import { ProposalVideoComponent } from './components/register/overview/proposal/proposal-video.component';
import { RoofLivingSituationComponent } from './components/register/roof-details/roof-living-situation/roof-living-situation.component';
import { ProposalPersonalDetailsComponent } from './components/register/overview/proposal/proposal-personal-details.component';
import { CarouselComponent } from '@common/components/carousel/carousel.component';
import { TooltipOnWordComponent } from './components/tooltip-on-word/tooltip-on-word.component';
import { ProposalPanelExplanationComponent } from './components/register/proposal/proposal-panel-explanation/proposal-panel-explanation.component';
import { TargetedRolloverCommandComponent } from './components/targeted-rollover-command/targeted-rollover-command.component';
import { DeviceTypeService } from '@country/nl/services/device-type.service';
import { RedirectFromSocialComponent } from './components/redirect-from-social/redirect-from-social.component';
import { RedirectToSocialComponent } from './components/redirect-to-social/redirect-to-social.component';
import { ShareAuctionSocialMediaCtaComponent } from '@country/nl/components/common/share-auction-social-media-cta/share-auction-social-media-cta.component';
import { SocialLinkGeneratorService } from '@common/services/social-link-generator.service';
import { TellAFriendLandingPageComponent } from './components/tell-a-friend-landing-page/tell-a-friend-landing-page.component';
import { ProposalSidebarSupplierLogoComponent } from './components/register/overview/proposal/proposal-sidebar-supplier-logo.component';
import { LoadingOverlayComponent } from '@country/nl/components/common/loading-overlay/loading-overlay.component';
import { ErrorAlertComponent } from '@country/nl/components/common/error-alert/error-alert.component';
import { AppConfigService, APP_CONFIG } from './services/app-config.service';
import { AppConfig } from './model/app-config';
import { SpNlMonitoringService } from "./services/monitoring.service";
import { MonitoringService } from "@common/services/monitoring.service";

registerLocaleData(localeNL, 'nl-NL');

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        AppCommonCoreModule,
        CallToActionModule,
        AppRoutingModule,
        NgbModule,
        FileUploadModule,
        BrowserModule,
        AppCommonModule,
        NgxMaskModule.forRoot(),
        OAuthModule.forRoot(),
        NgSelectModule,
    ],
    declarations: [
        AppComponent,

        FormatAddressPipe,
        FullNamePipe,

        RoofDetailsComponent,
        RoofTypeComponent,
        RoofSizeComponent,
        RoofObjectsComponent,
        RoofEnergyComponent,
        RoofYieldComponent,
        RoofFormButtonsComponent,
        RoofExcludedWarningComponent,
        RoofLivingSituationComponent,
        StepTitleComponent,
        UnauthorizedComponent,
        UserChangedComponent,
        RegisterComponent,
        RegisterRedirectComponent,
        RegisterStepperComponent,
        RegisterErrorComponent,
        RegisterWarningComponent,
        RegisterTextErrorComponent,
        RegisterInputErrorsComponent,
        RegisterWarningButtonComponent,
        RegisterExcludeButtonComponent,
        RegisterCancelLinkComponent,
        RegisterStatusMessageComponent,
        RegisterFaqComponent,
        FaqCategoryComponent,
        PersonComponent,
        WelcomeComponent,
        PaymentComponent,
        HeroComponent,
        CancelComponent,
        CancelFormComponent,
        CancelSuccessComponent,
        CancelErrorComponent,
        CancelSidebarComponent,
        CancelOverviewTitleComponent,
        CancelNextStepsComponent,
        RegistrationBarComponent,
        OverviewComponent,
        OrderDetailsComponent,
        ProposalAttachmentsComponent,
        OverviewChangeDetailsComponent,
        OverviewNextStepsComponent,
        MenuComponent,
        FooterComponent,
        FaqComponent,
        DepositComponent,
        ProposalComponent,
        ProposalContactDataComponent,
        ProposalRoofDataComponent,
        ProposalOfferComponent,
        ProposalAdvantageComponent,
        ProposalInstallationComponent,
        ProposalNotesToSupplierComponent,
        ProposalSupplierConditionsComponent,
        ProposalDeclineModalComponent,
        ProposalCountdownComponent,
        SupplierLogoComponent,
        ContactComponent,
        SeminarComponent,
        SeminarCardComponent,
        ExcludeReasonComponent,
        ExcludedComponent,
        PaymentSuccessComponent,
        InterestedIndividualComponent,
        EmailConfirmedComponent,
        DepositPaidComponent,
        OfferDeclinedComponent,
        UserCardComponent,
        LoadingSpinnerComponent,
        TermsAndConditionsComponent,
        PrivacyStatementComponent,
        DepositPendingComponent,
        DepositFailedComponent,
        DecisionMadeComponent,
        ProductComponent,
        OfferAcceptedComponent,
        ErrorStepComponent,
        ClosedComponent,
        CancelledComponent,
        ContactPreferencesComponent,
        CancelRegistrationConfirmModalComponent,
        SpnlTooltipComponent,
        RedirectToPreferenceCenterComponent,
        ProposalVideoComponent,
        ProposalPersonalDetailsComponent,
        CarouselComponent,
        TooltipOnWordComponent,
        ProposalPanelExplanationComponent,
        TargetedRolloverCommandComponent,
        RedirectFromSocialComponent,
        ShareAuctionSocialMediaCtaComponent,
        RedirectToSocialComponent,
        TellAFriendLandingPageComponent,
        ProposalSidebarSupplierLogoComponent,
        LoadingOverlayComponent,
        ErrorAlertComponent,
    ],
    providers: [
        {
            provide: AppConfigService,
            useFactory: (config: AppConfig) => new AppConfigService(config),
            deps: [APP_CONFIG],
        },
        RegistrationService,
        RegistrationApiService,
        RegistrationStoreService,
        RegisterStoreService,
        ReferrerService,
        PaymentService,
        AddressService,
        VwoService,
        VwoStoreService,
        AuthService,
        ContentTranslatorService,
        SolarApiService,
        SupplierService,
        ContentService,
        KenalyticsService,
        InMemoryQueryService,
        RegistrationButtonContentService,
        AuctionService,
        ContactService,
        RegisterStepService,
        RegisterSaveService,
        RegisterLocationService,
        RegisterAnalyticsService,
        RegisterFlowCalculator,
        RoofDetailsLocationService,
        FaqAnalyticsService,
        InstallationService,
        DigitalEventService,
        ChatService,
        RegisterStateService,
        PreferencesService,
        DeviceTypeService,
        PendingChangesGuard,
        SocialLinkGeneratorService,

        WINDOW_PROVIDERS,

        { provide: LOCALE_ID, useValue: 'nl-NL' },
        {
            provide: API_URL,
            useFactory: () => environment.apiUrl,
            deps: [AppConfigService],
        },
        {
            provide: COMMON_API_URL,
            useFactory: () => environment.commonApiUri ,
            deps: [AppConfigService],
        },
        { provide: CMS_URL, useValue: 'https://content-eu.ichoosr.com/api/' },
        { provide: CMS_PRODUCT, useValue: 'solarpanels' },
        { provide: CMS_REGION, useValue: 'nl' },
        { provide: CMS_LOCALE, useValue: 'nl' },
        {
            provide: CMS_ALLOW_LINKS,
            useFactory: () => environment.allowLinksToCms,
            deps: [AppConfigService], },
        { provide: BUSINESS, useValue: 'spnl' },
        { provide: CANONICAL_COMMUNITY, useValue: '' },
        { provide: OAuthStorage, useClass: InMemoryAuthStorage },
        { provide: ValidationHandler, useClass: JwksValidationHandler },
        {
            provide: AuthConfig,
            useFactory: () =>
            getAuthConfigFromEnv(environment, window?.location?.hostname),
            deps: [AppConfigService],
        },
        {
            provide: OAuthModuleConfig,
            useFactory: (): OAuthModuleConfig => {
                return {
                    resourceServer: {
                        allowedUrls: [environment.apiUrl],
                        sendAccessToken: true,
                    },
                };
            },
            deps: [AppConfigService],
        },
        { provide: ANALYTICS_API_URL,
            useFactory: () => environment.analyticsUri,
            deps: [AppConfigService],
         },
        { provide: PAYMENTS_API_URL,
            useFactory: () => environment.paymentsApiUri,
            deps: [AppConfigService],
        },
        {
            provide: COOKIE_POLICY_URL,
            useFactory: () => fetchCookiePolicyUrlFromEnv(
                environment.cookiePolicyUrlMapping,
                environment.cookiePolicyUrlMapping.default,
                window?.location?.hostname,
            ),
            deps: [AppConfigService],

        },
        { provide: DEFAULT_COMMUNITY, useValue: '' },
        {
            provide: ContentFallbackServiceBase,
            useClass: ContentFallbackService,
        },
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        { provide: CmsTextPipe, useClass: CmsTextPipe },
        {
            provide: AnalyticsEnricherProvider,
            deps: [RegistrationService, CommunityService],
        },
        {
            provide: ANALYTICS_SEND_TO_TAGMANAGER, // should be removed when fully moved to GA4
            useValue: false,
        },
        {
            provide: ANALYTICS_LOG_TO_CONSOLE, // should be removed when fully moved to GA4
            useFactory: () => !environment.production,
            deps: [AppConfigService],
        },
        { provide: MParticleService, useClass: NoOpMParticleService },
        {
            provide: MPARTICLE_KEY_PROVIDER_URL,
            useFactory: () =>  environment.production
                ? 'https://ichoosr-mparticle.ichoosr.com/api/SdkApiKeyOnlyProvider'
                : 'https://ichoosr-mparticle-test.ichoosr.com/api/SdkApiKeyOnlyProvider',
                deps: [AppConfigService],
        },
        {
            provide: MPARTICLE_DEVELOPMENT_MODE,
            useFactory: () => !environment.production,
            deps: [AppConfigService],
        },
        {
            provide: APPLICATION_INSIGHTS_CONNECTION_STRING,
            useFactory: () => environment.aiConnectionString,
            deps: [AppConfigService],
        },
        {
            provide: MonitoringService,
            useClass: SpNlMonitoringService,
            deps: [BUSINESS, APPLICATION_INSIGHTS_CONNECTION_STRING],
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
